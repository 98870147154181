<template>
  <div class="rules-container">
    <div class="filter-panel" style="display: none"></div>
    <div class="result-panel">
      <CSTable :thead-top="0">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
              class="btn btn-primary sticky-right"
              @click="PropertyFee(null)"
            >
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加物业费规则
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>规则名称</th>
            <th>物业计费标准</th>
            <th>拖欠处理期限</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in rulesList" :key="item.id">
            <td>{{ item.createTime }}</td>
            <td>{{ item.rulesName }}</td>
            <td>{{ item.amount }}元/m²/月</td>
            <td>{{ item.delinquentDate }}个月</td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li @click="PropertyFee(item)">
                    <a>修改</a>
                  </li>
                  <li @click="modifyFee(item.id)">
                    <a>删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <!-- //分页组件 -->
      <Pagination name="pagination" componentName="Pagination"></Pagination>
      <!-- 添加物业费规则 -->
      <CSDialog
        :dialogTitle="disposeResTitle"
        :dialogVisible="orderContentVisible"
        dialogWidth="812px" :is-submitting="isSubmitting"
        dialog-header-class="alert-bg"
        @onClose="orderContentVisible = false"
        @onConfirm="reopenWorkOrder"
        dialogHeaderClass="operation-bg"
      >
        <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
          <div style="margin-bottom: 23px">
            <label style="width: 300px; margin-right: 50px; text-align: right"
              >规则名称</label
            >
            <input
              type="text"
              placeholder="限10个字"
              maxlength="10"
              style="width: 400px; border: 1px solid #999; border-radius: 4px"
              v-model="editCompanyOwnerInfo.companyName"
            />
          </div>
          <div style="margin-bottom: 23px">
            <label style="width: 300px; margin-right: 50px; text-align: right"
              >物业计费标准(元/m²/月)</label
            >
            <input
              type="number"
              placeholder="0-9999"
              maxlength="4"
              style="width: 400px; border: 1px solid #999; border-radius: 4px"
              v-model="editCompanyOwnerInfo.freightBasis"
              @input="formatMin(editCompanyOwnerInfo.freightBasis)"
            />
          </div>
          <div style="margin-bottom: 23px">
            <label style="width: 300px; margin-right: 50px; text-align: right"
              >拖欠处理时限</label
            >
            <CSSelect style="height: 40px" class="waterC">
              <select
                v-model="editCompanyOwnerInfo.timerId"
                @change="() => {}"
                style="margin-left: 5px;color: #999999"
              >
                <option value="">请选择</option>
                <option
                  v-for="department in dateList"
                  :key="department"
                  :value="department"
                >
                  {{ department }}个月
                </option>
              </select>
            </CSSelect>
            后关闭水电表预存
          </div>
        </div>
      </CSDialog>
    </div>
    <!-- //删除提示 -->
    <DeleteOwnerOver></DeleteOwnerOver>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import DeleteOwnerOver from "@/components/DeleteOwnerOver.vue";
import {
  queryRulesUrl,
  addRulesUrl,
  deleteRulesUrl,
  editRulesUrl,
} from "@/requestUrl";
export default {
  data() {
    return {
      stateOperation: 1,
      rulesList: [],
      isSubmitting: false,
      disposeResTitle: "添加物业费规则",
      orderContentVisible: false,
      editCompanyOwnerInfo: {
        companyName: "", //规则名称
        freightBasis: "", //计费标准
        timerId: "", //拖欠处理多长时间后关闭水电表
        id: "",
      },
      dateList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  components: {
    Pagination,
    CSTable,
    CSDialog,
    CSSelect,
    DeleteOwnerOver,
  },
  methods: {
    //查询物业计费规则列表
    queryRule(pageN = 1, pageS = 10) {
      this.$fly
        .post(queryRulesUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          pageSize: pageS, //页面大小
          pageNo: pageN, //页码
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.rulesList = res.data.datas;
          if (pageN == 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageN,
              pageSize: pageS,
            });
          }
        });
    },
    //保存物业费规则
    reopenWorkOrder() {
        this.isSubmitting = true;
      if (this.stateOperation == 1) {

        this.$fly
          .post(addRulesUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            ruleName: this.editCompanyOwnerInfo.companyName, //计费规则名称
            ruleAmount: this.editCompanyOwnerInfo.freightBasis, //金额
            delinquentDate: this.editCompanyOwnerInfo.timerId, //拖欠处理日期
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("添加物业费规则成功");
            this.queryRule();
            this.orderContentVisible = false;
          }).finally(() => this.isSubmitting = false);
      } else {
        this.$fly
          .post(editRulesUrl, {
            id: this.editCompanyOwnerInfo.id, //计费规则id
            ruleName: this.editCompanyOwnerInfo.companyName, //计费规则名称
            ruleAmount: this.editCompanyOwnerInfo.freightBasis, //金额
            delinquentDate: this.editCompanyOwnerInfo.timerId, //拖欠处理日期
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("修改物业费规则成功");
            this.queryRule();
            this.orderContentVisible = false;
          }).finally(() => this.isSubmitting = false);
      }
    },
    //限制输入框数字大小
    formatMin(e) {
      if (!/^-?\d+\.?\d{0,2}$/.test(this.editCompanyOwnerInfo.freightBasis)) {
          this.editCompanyOwnerInfo.freightBasis = this.editCompanyOwnerInfo.freightBasis.substring(0, this.editCompanyOwnerInfo.freightBasis.indexOf(".") + 3);
        }
      if (+e < 0) {

        this.editCompanyOwnerInfo.freightBasis = "";
      }
      if(+e > 99999){
        this.editCompanyOwnerInfo.freightBasis = 99999
      }
    },
    //添加物业费规则
    PropertyFee(item) {
      if (item) {
        this.disposeResTitle = "修改物业计费规则";
        this.stateOperation = 2;
        this.editCompanyOwnerInfo.companyName = item.rulesName;
        this.editCompanyOwnerInfo.freightBasis = item.amount; //金额
        this.editCompanyOwnerInfo.timerId =
          this.dateList[item.delinquentDate - 1];
        this.editCompanyOwnerInfo.id = item.id;
      } else {
        this.disposeResTitle = "添加物业费规则";
        this.stateOperation = 1;
        this.editCompanyOwnerInfo.companyName = "";
        this.editCompanyOwnerInfo.freightBasis = ""; //金额
        this.editCompanyOwnerInfo.timerId = "";
        this.editCompanyOwnerInfo.id = "";
      }
      this.orderContentVisible = true;
    },
    //删除物业费规则
    modifyFee(item) {
      this.$vc.emit(this.$route.path, "deleteTenant", "openOwnerModelOver", {
        id: item,
      });
    },
  },
  mounted() {
    this.queryRule();
    this.$vc.on(
      this.$route.path,
      "deleteTenantOn",
      "openOwnerModelOverOn",
      (item) => {
        console.log(item);
        //删除规则
        this.$fly
          .get(deleteRulesUrl, {
            id: item.id,
          })
          .then((res) => {
            console.log(item);
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("删除物业费规则成功");
            this.queryRule();
          });
      }
    );
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.queryRule(pageNo);
    });
  },
};
</script>

<style>
.rules-container .el-icon-caret-bottom{
  padding-top: 4px;
  width: 100px !important;
}
.waterC{

}
</style>
